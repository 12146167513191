<template>
  <v-container fluid>
    <base-material-card
      color="primary"
      icon="mdi-file-eye-outline"
      inline
      class="px-5 py-4 ml-0 pb-10"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Andamento #{{ execucao.id }}
        </div>
      </template>
      <v-divider class="my-4" />
      <span id="importacao-bdgd-logs-first-sec">
        <v-row>
          <v-col cols="6">
            <label class="d-block mb-1">Mês dos dados:</label>
            <v-label>
              {{ execucao.data_registro | formatToMonth }}
              v{{ execucao.versao }}
            </v-label>
          </v-col>
          <v-col cols="6">
            <label class="d-block mb-1">Servidor:</label>
            <v-label>{{ execucao.servidor }}</v-label>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <label class="d-block mb-1">Número de linhas:</label>
            <v-label>
              {{ execucao.numero_linhas | parseNumberToIntegerBR }}
            </v-label>
          </v-col>
          <v-col cols="6">
            <label class="d-block mb-1">Criada por:</label>
            <v-label>
              {{ execucao.usuario }} em
              {{ execucao.created | parseToDateTimeBR }}
            </v-label>
          </v-col>
        </v-row>
      </span>
      <v-divider class="my-4" />
      <aux-log-data-table
        v-if="!loading"
        :servidorId="execucao.servidor_id"
        :bdgdVersaoId="execucao.bdgd_versao_id"
        :databasePerdas="execucao.database_perdas"
      />
    </base-material-card>
  </v-container>
</template>

<script>
import AuxLogDataTable from '@/components/perdas-tecnicas/preencher-tabelas-operacionais/AuxLogDataTable.vue';
import ExecucoesProcedurePrincipalGeoPerdasService from '@/services/ExecucoesProcedurePrincipalGeoPerdasService';

export default {
  data() {
    return {
      execucao: {},
      loading: true
    };
  },
  components: {
    AuxLogDataTable
  },
  mounted() {
    this.getDadosExecucao();
  },
  methods: {
    getDadosExecucao() {
      const id = this.$route.params.id;
      ExecucoesProcedurePrincipalGeoPerdasService.getExecucao(id)
        .then(({ data }) => (this.execucao = data.data))
        .catch((error) => {
          this.$toast.error(
            'Ocorreu um erro ao buscar os logs da execução da procedure principal.',
            '',
            {
              position: 'topRight'
            }
          );
          console.error('Erro:', error);
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style></style>
