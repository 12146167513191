<template>
  <div>
    <v-data-table
      class="execucoes-procedure-table"
      :headers="headers"
      :items="items"
      :search.sync="search"
      :mobile-breakpoint="800"
      :loading="loading"
      :hide-default-header="loading"
      :hide-default-footer="loading"
      :no-data-text="loading ? 'Carregando dados' : ''"
    >
      <template v-slot:top>
        <div class="d-flex justify-space-between">
          <v-icon
            v-if="!loading"
            color="primary"
            @click="getAuxLog"
          >
            mdi-refresh
          </v-icon>
          <v-text-field
            v-if="!loading"
            id="execucoes-procedure-historico-search"
            v-model="search"
            append-icon="mdi-magnify"
            class="break-search"
            label="Procurar"
            hide-details
            single-line
            style="max-width: 250px"
          />
        </div>
      </template>
      <template v-slot:[`item.DataHoraLog`]="{ item }">
        <div id="data_hora_log">
          {{ item.DataHoraLog | parseToDateTimeBR }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ExecucoesProcedurePrincipalGeoPerdasService from '@/services/ExecucoesProcedurePrincipalGeoPerdasService';
import refreshDataMixins from '@/mixins/refreshDataMixins';

export default {
  name: 'AuxLogDataTable',
  mixins: [refreshDataMixins],
  props: {
    servidorId: {
      type: Number,
      required: true
    },
    bdgdVersaoId: {
      type: Number,
      required: true
    },
    databasePerdas: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      items: [],
      search: '',
      loading: true,
      secondsToRefresh: 30,
      headers: [
        {
          text: 'Data e hora',
          value: 'DataHoraLog'
        },
        {
          text: 'Log de execução',
          value: 'NomeLog'
        }
      ]
    };
  },
  mounted() {
    this.refreshData(this.getAuxLog);
  },
  methods: {
    getAuxLog() {
      ExecucoesProcedurePrincipalGeoPerdasService.getAuxLogs(
        this.servidorId,
        this.bdgdVersaoId,
        this.databasePerdas
      )
        .then((response) => (this.items = response))
        .catch((error) => {
          this.$toast.error(
            'Ocorreu um erro ao buscar os dados da tabela AuxLog.',
            '',
            {
              position: 'topRight'
            }
          );
          console.error(error);
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
